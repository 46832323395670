define("ember-changeset/index", ["exports", "validated-changeset", "ember-changeset/utils/merge-deep", "ember-changeset/utils/is-object"], function (_exports, _validatedChangeset, _mergeDeep, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildOldValues = buildOldValues;
  _exports.changeset = changeset;
  _exports.Changeset = Changeset;
  _exports.default = _exports.EmberChangeset = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CHANGES = '_changes';
  const PREVIOUS_CONTENT = '_previousContent';
  const CONTENT = '_content';

  const defaultValidatorFn = () => true;

  function buildOldValues(content, changes, getDeep) {
    const obj = Object.create(null);

    for (let change of changes) {
      obj[change.key] = getDeep(content, change.key);
    }

    return obj;
  }

  function isProxy(o) {
    return !!(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  }

  function maybeUnwrapProxy(o) {
    return isProxy(o) ? maybeUnwrapProxy(Ember.get(o, 'content')) : o;
  }

  let EmberChangeset = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._dependentKeyCompat, _dec5 = Ember._dependentKeyCompat, _dec6 = Ember._dependentKeyCompat, _dec7 = Ember._dependentKeyCompat, (_class = class EmberChangeset extends _validatedChangeset.BufferedChangeset {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "_changes", _descriptor, this);

      _initializerDefineProperty(this, "_errors", _descriptor2, this);

      _initializerDefineProperty(this, "_content", _descriptor3, this);

      _defineProperty(this, "isObject", _isObject.default);

      _defineProperty(this, "maybeUnwrapProxy", maybeUnwrapProxy);

      _defineProperty(this, "getDeep", Ember.get);

      _defineProperty(this, "mergeDeep", _mergeDeep.default);
    }

    // override base class
    safeGet(obj, key) {
      return Ember.get(obj, key);
    }

    safeSet(obj, key, value) {
      return Ember.set(obj, key, value);
    }
    /**
     * @property isValid
     * @type {Array}
     */


    get isValid() {
      return super.isValid;
    }
    /**
     * @property isInvalid
     * @type {Boolean}
     */


    get isInvalid() {
      return super.isInvalid;
    }
    /**
     * @property isPristine
     * @type {Boolean}
     */


    get isPristine() {
      return super.isPristine;
    }
    /**
     * @property isDirty
     * @type {Boolean}
     */


    get isDirty() {
      return super.isDirty;
    }

    get pendingData() {
      let content = this[CONTENT];
      let changes = this[CHANGES];
      let pendingChanges = this.mergeDeep(Object.create(Object.getPrototypeOf(content)), content, {
        safeGet: Ember.get,
        safeSet: Ember.set
      });
      return this.mergeDeep(pendingChanges, changes, {
        safeGet: Ember.get,
        safeSet: Ember.set
      });
    }
    /**
     * Manually add an error to the changeset. If there is an existing
     * error or change for `key`, it will be overwritten.
     *
     * @method addError
     */


    addError(key, error) {
      super.addError(key, error);
      Ember.notifyPropertyChange(this, key); // Return passed-in `error`.

      return error;
    }
    /**
     * Manually push multiple errors to the changeset as an array.
     *
     * @method pushErrors
     */


    pushErrors(key, ...newErrors) {
      const {
        value,
        validation
      } = super.pushErrors(key, ...newErrors);
      Ember.notifyPropertyChange(this, key);
      return {
        value,
        validation
      };
    }
    /**
     * Sets property or error on the changeset.
     * Returns value or error
     */


    _setProperty({
      key,
      value,
      oldValue
    }) {
      super._setProperty({
        key,
        value,
        oldValue
      });

      Ember.notifyPropertyChange(this, key);
    }
    /**
     * Notifies virtual properties set on the changeset of a change.
     * You can specify which keys are notified by passing in an array.
     *
     * @private
     * @param {Array} keys
     * @return {Void}
     */


    _notifyVirtualProperties(keys) {
      keys = super._notifyVirtualProperties(keys);
      (keys || []).forEach(key => Ember.notifyPropertyChange(this, key));
      return;
    }
    /**
     * Deletes a key off an object and notifies observers.
     */


    _deleteKey(objName, key = '') {
      const result = super._deleteKey(objName, key);

      Ember.notifyPropertyChange(this, key);
      return result;
    }
    /**
     * Executes the changeset if in a valid state.
     *
     * @method execute
     */


    execute() {
      let oldContent;

      if (this.isValid && this.isDirty) {
        let content = this[CONTENT];
        let changes = this[CHANGES]; // keep old values in case of error and we want to rollback

        oldContent = buildOldValues(content, this.changes, this.getDeep); // we want mutation on original object
        // @tracked

        this[CONTENT] = this.mergeDeep(content, changes, {
          safeGet: Ember.get,
          safeSet: Ember.set
        });
      }

      this[PREVIOUS_CONTENT] = oldContent;
      return this;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_changes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_errors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_content", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isValid", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInvalid", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isInvalid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPristine", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isPristine"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDirty", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isDirty"), _class.prototype)), _class));
  /**
   * Creates new changesets.
   */

  _exports.EmberChangeset = EmberChangeset;

  function changeset(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
    (true && !(Boolean(obj)) && Ember.assert('Underlying object for changeset is missing', Boolean(obj)));
    (true && !(!Array.isArray(obj)) && Ember.assert('Array is not a valid type to pass as the first argument to `changeset`', !Array.isArray(obj)));

    if (options.changeset) {
      return new options.changeset(obj, validateFn, validationMap, options);
    }

    const c = new EmberChangeset(obj, validateFn, validationMap, options);
    return c;
  }
  /**
   * Creates new changesets.
   * @function Changeset
   */


  function Changeset(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
    const c = changeset(obj, validateFn, validationMap, options);
    return new Proxy(c, {
      get(targetBuffer, key
      /*, receiver*/
      ) {
        const res = targetBuffer.get(key.toString());
        return res;
      },

      set(targetBuffer, key, value
      /*, receiver*/
      ) {
        targetBuffer.set(key.toString(), value);
        return true;
      }

    });
  }

  class ChangesetKlass {
    /**
     * Changeset factory
     * TODO: deprecate in favor of factory function
     *
     * @class ChangesetKlass
     * @constructor
     */
    constructor(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
      const c = changeset(obj, validateFn, validationMap, options);
      return new Proxy(c, {
        get(targetBuffer, key
        /*, receiver*/
        ) {
          const res = targetBuffer.get(key.toString());
          return res;
        },

        set(targetBuffer, key, value
        /*, receiver*/
        ) {
          targetBuffer.set(key.toString(), value);
          return true;
        }

      });
    }

  }

  _exports.default = ChangesetKlass;
});